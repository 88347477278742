.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.off-canvas {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -300px; 
  display: flex;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: translateX(300px); 
  transition: transform 0.3s ease-out;
  z-index: 999999;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  box-shadow: none!important;
}

/* Date Picker */
.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border: transparent!important;
  padding-left: 0.6rem;
}

.react-date-picker__inputGroup__input,
.react-time-picker__inputGroup__input {
  height: auto!important;
  font-size: inherit!important;
  padding-right: 0!important;
  padding-left: 0!important;
}

.react-calendar {
  border-radius: 5px;
  border: 1px solid #ddd!important;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)!important;
}

.react-calendar__tile--active {
  color: rgb(79 70 229 / 1)!important;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: rgb(79 70 229 / 1)!important;
  color: #FFF!important;
}

.react-date-picker__inputGroup {
  white-space: nowrap!important;
}

.react-date-picker__inputGroup__input:invalid,
.react-time-picker__inputGroup__input:invalid {
  background-color: transparent!important;
}

.react-date-picker__inputGroup input,
.react-time-picker__inputGroup input {
  line-height: 1.2rem!important;
}

.react-date-picker__inputGroup input[type=number]::-webkit-outer-spin-button,
.react-date-picker__inputGroup input[type=number]::-webkit-inner-spin-button,
.react-time-picker__inputGroup input[type=number]::-webkit-outer-spin-button,
.react-time-picker__inputGroup input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-date-picker__inputGroup input[type=number],
.react-time-picker__inputGroup input[type=number] {
    -moz-appearance:textfield;
}

.react-time-picker__wrapper {
  border: transparent!important;
}

.react-time-picker__inputGroup {
  padding: 7px 2px;
}
/* /Date Picker */

/* /Phone Number */
.react-tel-input .form-control {
  width: 100%!important;
  height: 38px!important;
}
/* /Phone Number */

/* /Tag input */
.rti--container {
  gap: 2px!important;
  padding: 4px!important;
}

.rti--input {
  padding-top: 2px!important;
  padding-bottom: 2px!important;
}
/* /Tag input */

.react-select__menu {
  z-index: 2000!important;
}

@media (min-width: 1024px) {
  .grandizer-app-header {
    z-index: 9999!important;
  }
}

.user-picker-disabled {
  color: rgb(163, 163, 163)!important;
}

/* Loader */
.grandizer-loader {
  --height-of-loader: 4px;
  --loader-color: #4338ca;
  width: 60%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0,0,0,0.2);
  position: relative;
}
 
.grandizer-loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
}
 
@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}


.main {
  width: 50%;
  margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
/* /Loader */
