@tailwind base;
@tailwind components;
@tailwind utilities;

/* START - Components */
@layer components {
    .g-input {
      @apply block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm;
    }

    .g-default-btn-sm {
        @apply inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0;
    }
}
/* END - Components */

@import 'react-toastify/dist/ReactToastify.css';

.leaflet-container {
    height: 300px;
}

/* width */
.grandizer-scrollbar::-webkit-scrollbar {
    width: 6px;
}
  
/* Track */
.grandizer-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    
}

/* Handle */
.grandizer-scrollbar::-webkit-scrollbar-thumb {
    background: #333; 
    margin-right: 1px;
    border-radius: 1.5px;
}

/* Handle on hover */
.grandizer-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141); 
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #333; 
    margin-right: 1px;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141); 
}

.beta-badge {
    color: red;margin-left: 5px; position: absolute; right: 25px; top: 0;
    font-size: 9px;
}

.beta-badge-child {
    right: 10px;
}